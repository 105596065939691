<template>
  <div :class="$style.root">
    <div v-if="isDev" :class="$style.dev">
      <h1>Development</h1>
      Current step: <input v-model="step"><br><br>
      <button @click="reset">Reset</button>
    </div>
    <transition name="caa-fade" mode="out-in">
      <div v-if="!answerToA" key="null">
        <h1 :class="$style.title">
          Find out if your property is subject to AB 1482’s Just Cause and/or Rent Cap Provisions
        </h1>
      </div>
      <div v-if="answerToA === 'B'" key="B">
        <h1 :class="$style.title">
          Is my rental unit subject to the just cause provisions of AB 1482?
        </h1>
      </div>
      <div v-if="answerToA === 'AH'" key="AH">
        <h1 :class="$style.title">
          Is my rental unit subject to the rent cap provisions of AB 1482?
        </h1>
      </div>
    </transition>
    <transition :name="`caa-frame-${direction}`" mode="out-in">
        <component :is="frame" @select="pushHistory"></component>
    </transition>
    <div :class="$style.disclaimer">
      <p>
        For purposes of this website, a rental unit does not include (1) transient and tourist
        hotel occupancy, (2) housing accommodations in a nonprofit hospital, religious facility,
        extended care facility, licensed residential care facility for the elderly, or an adult
        residential facility, or (3) dormitories owned and operated by an institution of higher
        education, kindergarten, or school for grades 1 to 12.
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import steps from './steps'

export default {
  computed: {
    ...mapState([
      'direction',
      'history',
    ]),
    step: {
      get() {
        return this.$store.state.step
      },
      set(value) {
        this.setStep(value)
      },
    },
    frame() {
      return steps[this.step]
    },
    answerToA() {
      return (this.history.length > 1) ? this.history[1] : null
    },
    isDev() {
      return process.env.NODE_ENV === 'development'
    },
  },
  methods: {
    ...mapActions([
      'init',
      'pushHistory',
      'reset',
      'setStep',
    ]),
  },
  created() {
    // Loads state from storage if applicable
    this.init()
  },
}
</script>

<style module>
.root p {
  margin-top: 1em;
  margin-bottom: 1em;
}

.dev {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 100;
  padding: 1em;
  font-size: .75em;
  border: 2px dashed #ddd;
}

.dev h1 {
  margin-top: 0;
  font-size: 1em;
  font-weight: bold;
}

.dev input {
  width: 1.5em;
  text-align: center;
}

.dev button {
  font-size: 1em;
}

.title {
  font-size: 1em;
  font-weight: bold;
  padding-bottom: .5em;
  border-bottom: 2px solid #e0e0e0;
}

.disclaimer {
  margin-top: 3em;
  font-size: .625em;
  opacity: .75;
}
</style>

<style>
.caa-frame-forward-enter-active,
.caa-frame-forward-leave-active,
.caa-frame-backward-enter-active,
.caa-frame-backward-leave-active {
  transition-property: opacity, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.7, 0, .265, 1);
}

.caa-frame-forward-enter,
.caa-frame-forward-leave-to,
.caa-frame-backward-enter,
.caa-frame-backward-leave-to {
  opacity: 0;
}

.caa-frame-forward-enter,
.caa-frame-backward-leave-to {
  transform: translateX(2%);
}

.caa-frame-forward-leave-to,
.caa-frame-backward-enter {
  transform: translateX(-2%);
}

.caa-fade-enter-active,
.caa-fade-leave-active {
  transition: opacity .15s;
}

.caa-fade-enter,
.caa-fade-leave-to {
  opacity: 0;
}
</style>
