<template>
  <button v-bind="attrs" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
import { GLOBAL_CONFIG_KEY } from '../config'

export default {
  data: () => ({
    configKey: 'buttonAttrs',
  }),
  computed: {
    attrs() {
      return (window[GLOBAL_CONFIG_KEY] || {})[this.configKey] || {}
    },
  },
}
</script>
