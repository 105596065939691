import { version } from '../../package.json'
import defaultState from './state'
import { STORAGE_KEY, DIRECTION_FORWARD, DIRECTION_BACKWARD } from './mutations'

export default {
  init({ commit }) {
    if (localStorage.getItem(STORAGE_KEY)) {
      const store = JSON.parse(localStorage.getItem(STORAGE_KEY))

      if (store.version === version) {
        commit('REPLACE', store)
      } else {
        commit('VERSION', version)
      }
    }
  },
  reset({ commit }) {
    commit('REPLACE', defaultState())
  },
  setStep({ commit }, value) {
    commit('STEP', value)
  },
  pushHistory({ commit, dispatch }, step) {
    commit('HISTORY_PUSH', step)
    dispatch('setDirection', DIRECTION_FORWARD)
    dispatch('setStep', step)
  },
  popHistory({ commit, dispatch, state }) {
    const previous = state.history[state.history.length - 2]
    commit('HISTORY_POP')
    dispatch('setDirection', DIRECTION_BACKWARD)
    dispatch('setStep', previous)
  },
  resetHistory({ commit }) {
    commit('HISTORY_RESET')
  },
  setDirection({ commit }, value) {
    commit('DIRECTION', value)
  },
  setCertDate({ commit }, value) {
    commit('CERT_DATE', value)
  },
}
