// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".text_outcome_3lYoH{font-weight:700;text-transform:uppercase}.text_disclaimer_oFs1D{font-size:.75em}", ""]);
// Exports
exports.locals = {
	"outcome": "text_outcome_3lYoH",
	"disclaimer": "text_disclaimer_oFs1D"
};
module.exports = exports;
