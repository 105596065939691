<template>
  <Terminal>
    <p>
      CAA has developed a flowchart to help its members in the City of Sacramento determine which
      law applies for purposes of the just cause provisions.
    </p>
    <p>
      <a href="https://caanet.org/app/uploads/2019/10/Flow-chart-AB-1482-v.-TRPA-just-cause.pdf"
      target="_blank"><strong>View Flowchart</strong></a>
    </p>
  </Terminal>
</template>

<script>
import Terminal from '../Terminal.vue'

export default {
  components: {
    Terminal,
  },
}
</script>
