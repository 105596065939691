// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OccupancyForm_root_3_Cph{margin-top:0;text-align:center}.OccupancyForm_root_3_Cph button{margin-top:0!important;margin-left:1em}", ""]);
// Exports
exports.locals = {
	"root": "OccupancyForm_root_3_Cph"
};
module.exports = exports;
