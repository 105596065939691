<template>
  <div :class="$style.root">
    <form @submit.prevent="isExempt">
      <p>Enter date of the certificate of occupancy</p>
      <input class="input" type="date" v-model="certDate" required>
      <OccupancyButton>Calculate</OccupancyButton>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OccupancyButton from './OccupancyButton.vue'

export default {
  components: {
    OccupancyButton,
  },
  computed: {
    certDate: {
      get() {
        return this.$store.state.certDate
      },
      set(value) {
        this.setCertDate(value)
      },
    },
  },
  methods: {
    ...mapActions([
      'setCertDate',
    ]),
    isExempt() {
      const d = this.certDate.split('-')
      const year = parseInt(d[0], 10)
      const month = parseInt(d[1], 10) - 1
      const day = parseInt(d[2], 10)

      const fifteenYearsAgo = new Date()
      fifteenYearsAgo.setFullYear(fifteenYearsAgo.getFullYear() - 15)
      const certDate = new Date(year, month, day)
      return certDate >= fifteenYearsAgo ? this.$emit('isExempt') : this.$emit('isNotExempt')
    },
  },
}
</script>

<style module>
.root {
  margin-top: 0;
  text-align: center;
}

.root button {
  margin-top: 0 !important;
  margin-left: 1em;
}
</style>
