<template>
  <Step>
    <p>
      Was the certificate of occupancy issued within the <strong>last 15 years?</strong>
    </p>
    <OccupancyForm @isExempt="$emit('select', 'AM')" @isNotExempt="$emit('select', 'AP')"/>
  </Step>
</template>

<script>
import OccupancyForm from '../OccupancyForm.vue'
import Step from '../Step.vue'

export default {
  components: {
    OccupancyForm,
    Step,
  },
}
</script>
