<template>
  <div :class="$style.root">
    <div :class="$style.question">
      <slot></slot>
    </div>
    <div v-if="canGoBack || canReset" :class="$style.back">
      <Choices>
        <BackButton v-if="canGoBack" @click="popHistory">Go back</BackButton>
        <ResetButton v-if="canReset" @click="reset">Start over</ResetButton>
      </Choices>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BackButton from './BackButton.vue'
import Choices from './Choices.vue'
import ResetButton from './ResetButton.vue'

export default {
  components: {
    BackButton,
    Choices,
    ResetButton,
  },
  computed: {
    ...mapState([
      'history',
    ]),
    canGoBack() {
      return this.history.length > 1
    },
    canReset() {
      return this.history.length > 2
    },
  },
  methods: {
    ...mapActions([
      'popHistory',
      'reset',
    ]),
  },
}
</script>

<style module>
.root {
  margin-top: 0 !important;
}

.question {
  font-size: 1.25em;
}

.back {
  margin-top: 1em;
  text-align: center;
}
</style>
