<template>
  <Terminal>
    <p>
      <strong>IF</strong> the local ordinance was adopted on or before <strong>September 1,
      2019</strong>, the unit is <span :class="$style.outcome">exempt</span> from AB 1482 just
      cause. The unit remains <span :class="$style.outcome">subject</span> to the local just cause
      ordinance.
    </p>
    <p>
      For later adopted local ordinances, the ordinance applies in place of AB 1482 if it is more
      protective of tenants.
    </p>
  </Terminal>
</template>

<script>
import Terminal from '../Terminal.vue'

export default {
  components: {
    Terminal,
  },
}
</script>

<style module>
.outcome {
  composes: outcome from "~@/assets/css/text.css";
}
</style>
