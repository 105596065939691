<template>
  <Terminal>
    <p>
      The unit is <span :class="$style.outcome">exempt</span> from AB 1482 just
      cause.
    </p>
  </Terminal>
</template>

<script>
import Terminal from '../Terminal.vue'

export default {
  components: {
    Terminal,
  },
}
</script>

<style module>
.outcome {
  composes: outcome from "~@/assets/css/text.css";
}
</style>
