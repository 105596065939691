// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-0-1!../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-0-2!../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-0-3!@/assets/css/text.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
exports.push([module.id, ".Section8Residents_disclaimer_2oXiA{}", ""]);
// Exports
exports.locals = {
	"disclaimer": "Section8Residents_disclaimer_2oXiA " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["disclaimer"] + ""
};
module.exports = exports;
