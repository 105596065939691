// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Terminal_root_1M0SQ{margin-top:0!important}.Terminal_result_1lC1l{font-size:1.25em}", ""]);
// Exports
exports.locals = {
	"root": "Terminal_root_1M0SQ",
	"result": "Terminal_result_1lC1l"
};
module.exports = exports;
