<template>
  <Terminal>
    <p>
      The unit is <span :class="$style.outcome">exempt</span> from the rent caps
      of AB 1482. The unit remains <span :class="$style.outcome">subject</span>
      to the local rent control ordinance. The unit may also be
      <span :class="$style.outcome">subject</span> to a 10% rent cap under the anti-price
      gouging law for the duration of a state or local emergency.
    </p>
    <AntiPriceGougingLaw/>
  </Terminal>
</template>

<script>
import AntiPriceGougingLaw from '../AntiPriceGougingLaw.vue'
import Terminal from '../Terminal.vue'

export default {
  components: {
    AntiPriceGougingLaw,
    Terminal,
  },
}
</script>

<style module>
.outcome {
  composes: outcome from "~@/assets/css/text.css";
}
</style>
