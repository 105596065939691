import Vue from 'vue'
import vueCustomElement from 'vue-custom-element'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

Vue.use(vueCustomElement)

Vue.customElement('caa-ab1482', App, {
  beforeCreateVueInstance(root) {
    root.store = store // eslint-disable-line no-param-reassign
    return root
  },
})
