<template>
  <Step>
    <p>Is the unit in the City of Sacramento?</p>
    <Choices>
      <ChoiceButton @click="$emit('select', 'C')">Yes</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'D')">No</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    Step,
  },
}
</script>
