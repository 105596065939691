<template>
  <Terminal>
    <p>
      The unit is <span :class="$style.outcome">exempt</span> from the rent caps
      of AB 1482 because it was built within the last 15 years.
    </p>
    <p>
      That exemption expires on <span :class="$style.outcome">{{ expDate }}</span>
      when the unit reaches 15 years of age. The landlord should determine whether
      a different exemption will apply when the unit reaches 15 years of age.
    </p>
    <p>
      The unit may be <span :class="$style.outcome">subject</span> to a 10% rent
      cap under the anti-price gouging law for the duration of a state or local emergency.
    </p>
    <AntiPriceGougingLaw/>
  </Terminal>
</template>

<script>
import AntiPriceGougingLaw from '../AntiPriceGougingLaw.vue'
import Terminal from '../Terminal.vue'

export default {
  components: {
    AntiPriceGougingLaw,
    Terminal,
  },
  computed: {
    expDate() {
      const d = this.$store.state.certDate.split('-')
      const year = parseInt(d[0], 10) + 15
      const month = parseInt(d[1], 10)
      const day = parseInt(d[2], 10)

      return `${month}/${day}/${year}`
    },
  },
}
</script>

<style module>
.outcome {
  composes: outcome from "~@/assets/css/text.css";
}
</style>
