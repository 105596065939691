<template>
  <Step>
    <p>
      Is the unit alienable separate from the title to any other unit (e.g., is it a condominium or
      single-family home with no other unit on the parcel)?
    </p>
    <Choices>
      <ChoiceButton @click="$emit('select', 'AO')">Yes</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'AL')">No</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    Step,
  },
}
</script>
