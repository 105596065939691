<template>
  <Terminal>
    <p>
      The unit is <span :class="$style.outcome">exempt</span> from the rent caps
      in AB 1482 <span :class="$style.outcome">until</span> subsidy agreement terminates.
      The unit may be <span :class="$style.outcome">subject</span> to a 10% rent
      cap under the anti-price gouging law for the duration of a state or local emergency.
    </p>
    <p>
      However, please note that if the unit is rented to a Section 8 resident, the unit <span
      :class="$style.outcome">MAY</span> be <span :class="$style.outcome">SUBJECT</span> to the rent
      caps in AB 1482 <span :class="$style.outcome">UNLESS</span> your property qualifies under
      another exemption. To determine if your property qualifies under another exemption, click “Go
      back” and then click “No” to the previous question and proceed to answer the rest of the
      questions.
    </p>
    <AntiPriceGougingLaw/>
    <Section8Residents/>
  </Terminal>
</template>

<script>
import AntiPriceGougingLaw from '../AntiPriceGougingLaw.vue'
import Section8Residents from '../Section8Residents.vue'
import Terminal from '../Terminal.vue'

export default {
  components: {
    AntiPriceGougingLaw,
    Section8Residents,
    Terminal,
  },
}
</script>

<style module>
.outcome {
  composes: outcome from "~@/assets/css/text.css";
}
</style>
