// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Step_root_1qNHs{margin-top:0!important}.Step_question_3pcXd{font-size:1.25em}.Step_back_1waQz{margin-top:1em;text-align:center}", ""]);
// Exports
exports.locals = {
	"root": "Step_root_1qNHs",
	"question": "Step_question_3pcXd",
	"back": "Step_back_1waQz"
};
module.exports = exports;
