import A from './components/steps/A.vue'
import B from './components/steps/B.vue'
import C from './components/steps/C.vue'
import D from './components/steps/D.vue'
import E from './components/steps/E.vue'
import F from './components/steps/F.vue'
import G from './components/steps/G.vue'
import H from './components/steps/H.vue'
import I from './components/steps/I.vue'
import J from './components/steps/J.vue'
import K from './components/steps/K.vue'
import L from './components/steps/L.vue'
import M from './components/steps/M.vue'
import N from './components/steps/N.vue'
import O from './components/steps/O.vue'
import P from './components/steps/P.vue'
import Q from './components/steps/Q.vue'
import R from './components/steps/R.vue'
import S from './components/steps/S.vue'
import T from './components/steps/T.vue'
import U from './components/steps/U.vue'
import V from './components/steps/V.vue'
import W from './components/steps/W.vue'
import X from './components/steps/X.vue'
import Y from './components/steps/Y.vue'
import Z from './components/steps/Z.vue'
import AA from './components/steps/AA.vue'
import AB from './components/steps/AB.vue'
import AC from './components/steps/AC.vue'
import AD from './components/steps/AD.vue'
import AE from './components/steps/AE.vue'
import AF from './components/steps/AF.vue'
// AG was incorrectly assigned, now skipped
import AH from './components/steps/AH.vue'
import AI from './components/steps/AI.vue'
import AJ from './components/steps/AJ.vue'
import AK from './components/steps/AK.vue'
import AL from './components/steps/AL.vue'
import AM from './components/steps/AM.vue'
import AN from './components/steps/AN.vue'
import AO from './components/steps/AO.vue'
import AP from './components/steps/AP.vue'
import AQ from './components/steps/AQ.vue'
import AR from './components/steps/AR.vue'
import AS from './components/steps/AS.vue'
import AT from './components/steps/AT.vue'
import AU from './components/steps/AU.vue'
import AV from './components/steps/AV.vue'
import AW from './components/steps/AW.vue'
import AX from './components/steps/AX.vue'
import AY from './components/steps/AY.vue'
import AZ from './components/steps/AZ.vue'
import BA from './components/steps/BA.vue'
import BB from './components/steps/BB.vue'
import BC from './components/steps/BC.vue'
import BD from './components/steps/BD.vue'
import BE from './components/steps/BE.vue'
import BF from './components/steps/BF.vue'
import BG from './components/steps/BG.vue'
import BH from './components/steps/BH.vue'

export default {
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  T,
  U,
  V,
  W,
  X,
  Y,
  Z,
  AA,
  AB,
  AC,
  AD,
  AE,
  AF,
  AH,
  AI,
  AJ,
  AK,
  AL,
  AM,
  AN,
  AO,
  AP,
  AQ,
  AR,
  AS,
  AT,
  AU,
  AV,
  AW,
  AX,
  AY,
  AZ,
  BA,
  BB,
  BC,
  BD,
  BE,
  BF,
  BG,
  BH,
}
