<template>
  <Step>
    <p>
      Is the unit <strong>either</strong> of the following:
    </p>
    <ol>
      <li>
        <p>
          An accommodation in which the tenant shares kitchen/bath facilities with the owner who
          maintains their principal residence on the property?
        </p>
      </li>
      <li>
        <p>
          On a property with a single-family owner-occupied residence in which the owner rents no
          more than 2 units or bedrooms?
        </p>
      </li>
    </ol>
    <Choices>
      <ChoiceButton @click="$emit('select', 'N')">Yes</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'O')">No</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    Step,
  },
}
</script>
