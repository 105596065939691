<template>
  <Step>
    <p>
      Is the owner of the unit a (1) real estate investment trust, (2) a
      corporation, or (3) a limited liability company in which at least one member
      is a corporation?
    </p>
    <Choices>
      <ChoiceButton @click="$emit('select', 'BH')">Yes</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'AS')">No</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    Step,
  },
}
</script>
