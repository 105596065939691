<template>
  <div :class="$style.root">
    <div :class="$style.result">
      <slot></slot>
    </div>
    <Choices>
      <BackButton @click="popHistory">Go back</BackButton>
      <ResetButton @click="reset">Start over</ResetButton>
    </Choices>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import BackButton from './BackButton.vue'
import Choices from './Choices.vue'
import ResetButton from './ResetButton.vue'

export default {
  components: {
    BackButton,
    Choices,
    ResetButton,
  },
  methods: {
    ...mapActions([
      'reset',
      'popHistory',
    ]),
  },
}
</script>

<style module>
.root {
  margin-top: 0 !important;
}

.result {
  font-size: 1.25em;
}
</style>
