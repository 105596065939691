export const STORAGE_KEY = 'caa_ab1482'
export const DIRECTION_FORWARD = 'forward'
export const DIRECTION_BACKWARD = 'backward'

export default {
  REPLACE(state, payload) {
    this.replaceState(Object.assign(state, payload))
  },
  VERSION(state, value) {
    state.version = value
  },
  STEP(state, value) {
    state.step = value
  },
  HISTORY_PUSH(state, value) {
    state.history.push(value)
  },
  HISTORY_POP(state) {
    state.history.pop()
  },
  HISTORY_RESET(state) {
    state.history = []
  },
  DIRECTION(state, value) {
    state.direction = value
  },
  CERT_DATE(state, value) {
    state.certDate = value
  },
}
