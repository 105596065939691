<script>
import Button from './Button.vue'

export default {
  extends: Button,
  data: () => ({
    configKey: 'resetButtonAttrs',
  }),
}
</script>
