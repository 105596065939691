<template>
  <Step>
    <p>
      Is the unit subject to an agreement that provides housing subsidies for affordable housing for
      persons of very low, low, or moderate income?
    </p>
    <Choices>
      <ChoiceButton @click="$emit('select', 'K')">Yes</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'M')">No</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    Step,
  },
}
</script>
