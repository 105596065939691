// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".App_root_2SJlR p{margin-top:1em;margin-bottom:1em}.App_dev_2Jlc9{position:absolute;top:1em;left:1em;z-index:100;padding:1em;font-size:.75em;border:2px dashed #ddd}.App_dev_2Jlc9 h1{margin-top:0;font-size:1em;font-weight:700}.App_dev_2Jlc9 input{width:1.5em;text-align:center}.App_dev_2Jlc9 button,.App_title_382G7{font-size:1em}.App_title_382G7{font-weight:700;padding-bottom:.5em;border-bottom:2px solid #e0e0e0}.App_disclaimer_qLD4C{margin-top:3em;font-size:.625em;opacity:.75}", ""]);
// Exports
exports.locals = {
	"root": "App_root_2SJlR",
	"dev": "App_dev_2Jlc9",
	"title": "App_title_382G7",
	"disclaimer": "App_disclaimer_qLD4C"
};
module.exports = exports;
