<template>
  <Step>
    <p>
      Is the unit subject to a local rent control ordinance that restricts
      annual increases in rent to an amount less than 5% + CPI?
    </p>
    <LocalRentControlOrdinance/>
    <Choices>
      <ChoiceButton @click="$emit('select', 'AK')">Yes</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'AN')">No</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import LocalRentControlOrdinance from '../LocalRentControlOrdinance.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    LocalRentControlOrdinance,
    Step,
  },
}
</script>
