// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Choices_root_1Loah{margin-top:0;text-align:center}.Choices_root_1Loah button{margin:.25em .5em!important}", ""]);
// Exports
exports.locals = {
	"root": "Choices_root_1Loah"
};
module.exports = exports;
