<template>
  <Step>
    <p></p>
    <Choices>
      <ChoiceButton @click="$emit('select', 'D')">Just Cause Provisions</ChoiceButton>
      <ChoiceButton @click="$emit('select', 'AH')">Rent Cap Provisions</ChoiceButton>
    </Choices>
  </Step>
</template>

<script>
import ChoiceButton from '../ChoiceButton.vue'
import Choices from '../Choices.vue'
import Step from '../Step.vue'

export default {
  components: {
    ChoiceButton,
    Choices,
    Step,
  },
}
</script>
