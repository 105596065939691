<template>
  <Terminal>
    <p>
      CAA has developed an Industry Insight to help members in the City of Sacramento comply with
      the city’s rent cap provisions.
    </p>
    <p>
      <a href="https://caanet.org/kb/sacramento-tenant-protection-and-relief-act-q-and-a/"
      target="_blank"><strong>See Industry Insight</strong></a>
    </p>
  </Terminal>
</template>

<script>
import Terminal from '../Terminal.vue'

export default {
  components: {
    Terminal,
  },
}
</script>
