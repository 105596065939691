<template>
  <Terminal>
    <p>
      The unit is <span :class="$style.outcome">exempt</span> from the rent caps
      in AB 1482 <span :class="$style.outcome">if</span> a notice is provided to
      the tenants. The unit may be <span :class="$style.outcome">subject</span>
      to a 10% rent cap under the anti-price gouging law for the duration of a
      state or local emergency.
    </p>
    <AntiPriceGougingLaw/>
  </Terminal>
</template>

<script>
import AntiPriceGougingLaw from '../AntiPriceGougingLaw.vue'
import Terminal from '../Terminal.vue'

export default {
  components: {
    AntiPriceGougingLaw,
    Terminal,
  },
}
</script>

<style module>
.outcome {
  composes: outcome from "~@/assets/css/text.css";
}
</style>
